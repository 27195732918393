import { useState, useMemo } from 'react';
import { Stack, Typography, Button, CircularProgress } from '@mui/material';
import { MuiFileInput } from 'mui-file-input';
import uploadApi, { Result } from '../api/upload';
import { useAuth } from '../Auth/Auth';

const getFileExtension = (fileWithExtension: string) => {
  const resultSplitted = fileWithExtension?.split('.');
  return resultSplitted[resultSplitted?.length - 1 || 0];
};

const FILE_SIZE = 10000000; // 10mb

export const AdminPage: React.FC = () => {
  const auth = useAuth();
  const [value, setValue] = useState<File | null>(null);
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState<Result | null>(null);
  const userTables = useMemo(
    () => auth?.user?.role?.translations,
    [auth?.user?.role?.translations]
  );

  const handleChange = (newValue: File | null) => {
    if (newValue) {
      const isNotValidFileSize = newValue?.size > FILE_SIZE;
      if (isNotValidFileSize) {
        return setError('El tamaño del archivo supera el límite de 10mb');
      }
      const fileExtension = getFileExtension(newValue?.name || '');
      if (!['csv', 'txt'].includes(fileExtension)) {
        return setError(
          `${fileExtension} no es una extensión válida, por favor importe archivos .csv o .txt`
        );
      }
    }
    setError('');
    setValue(newValue);
    setResult(null);
  };

  const handleOnClick = async () => {
    setResult(null);
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', value!);
      const responseProfile = await uploadApi.uploadFile(formData);
      const result = responseProfile?.data;
      setResult(result);
    } catch {
      setResult(null);
      setError(
        'Ha ocurrido un error al importar el archivo. Por favor contactar al administrador.'
      );
    }
    setValue(null);
    setIsLoading(false);
  };

  const clearButton = document.getElementsByClassName(
    'MuiFileInput-IconButton'
  );
  clearButton?.[0]?.setAttribute('title', 'Borrar');

  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      marginTop='50px'
      paddingX='20px'
      gap='20px'
    >
      <Typography fontSize='30px'>Importar archivo</Typography>
      <MuiFileInput value={value} onChange={handleChange} />
      {error && (
        <Typography color='red' marginTop='10px' textAlign='center'>
          {error}
        </Typography>
      )}
      {isLoading ? (
        <>
          <CircularProgress sx={{ color: '#255AA3', marginTop: '5px' }} />
          <Typography marginTop='10px' textAlign='center'>
            Por favor no cierre la ventana hasta obtener el resultado.
          </Typography>
        </>
      ) : (
        <Button
          variant='contained'
          sx={{ marginY: '10px' }}
          onClick={handleOnClick}
          disabled={!!error || !value || !userTables?.length}
        >
          Importar
        </Button>
      )}
      {result && (
        <Stack
          justifyContent='center'
          alignItems='center'
          marginTop='10px'
          paddingX='20px'
          gap='10px'
        >
          <Typography>Resultado</Typography>
          <Typography>Total Filas: {result.total}</Typography>
          <Typography>Filas Ok: {result.ok}</Typography>
          <Typography>Filas Error: {result.error}</Typography>
        </Stack>
      )}
      {!!userTables?.length && (
        <Stack
          justifyContent='center'
          alignItems='center'
          marginTop='10px'
          paddingX='20px'
          gap='10px'
        >
          <Typography fontWeight='900' sx={{ textDecoration: 'underline' }}>
            Archivos Disponibles a Importar
          </Typography>
          {userTables.map(
            ({ fileName, isActive }: { fileName: string; isActive: string }) =>
              isActive === 'SI' && (
                <Typography key={fileName}>{fileName}</Typography>
              )
          )}
        </Stack>
      )}
    </Stack>
  );
};
