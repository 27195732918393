import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { sha256 } from 'js-sha256';
import axios from './axiosConfig';

const config: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const performLogin = async (credentials: {
  username: string;
  password: string;
}): Promise<AxiosResponse<{ access_token: string }>> =>
  axios.post(
    '/login',
    {
      email: credentials.username,
      pass: sha256(credentials.password)?.toUpperCase(),
    },
    config
  );
const getProfile = async (): Promise<AxiosResponse<any>> =>
  axios.get('/users/me', config);

const authApi = { performLogin, getProfile };

export default authApi;
