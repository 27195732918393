import { useNavigate, useLocation } from 'react-router-dom';
import {
  Paper,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useAuth } from '../Auth/Auth';

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const from = location.state?.from?.pathname || '/';

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const username = formData.get('email') as string;
    const password = formData.get('password') as string;

    auth.signIn({ username, password }, () => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, { replace: true });
    });
  };

  return (
    <Box
      sx={{
        marginTop: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper
        variant='outlined'
        sx={{
          width: '300px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <Stack
          width='200px'
          gap='20px'
          justifyContent='center'
          alignItems='center'
        >
          <Typography fontWeight='900'>
            {!auth?.token || !auth?.user
              ? 'Acceso identificado'
              : 'Ya se encuentra logueado.'}
          </Typography>
          {(!auth?.token || !auth?.user) && (
            <form
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '20px',
              }}
            >
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input name='email' />
              </FormControl>
              <FormControl>
                <FormLabel>Contraseña</FormLabel>
                <Input name='password' type='password' />
              </FormControl>
              <Button
                variant='contained'
                type='submit'
                sx={{ backgroundColor: '#255AA3' }}
              >
                Acceder
              </Button>
              <Stack justifyContent='center' alignItems='center'>
                {auth?.isLoading && (
                  <CircularProgress sx={{ color: '#255AA3' }} />
                )}
                {auth?.error && (
                  <Typography color='red'>{auth.error}</Typography>
                )}
              </Stack>
            </form>
          )}
        </Stack>
      </Paper>
    </Box>
  );
};
