import axios, { AxiosHeaders } from 'axios';

export const BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:5555/api';

const _axios = axios.create({
  baseURL: BASE_URL,
});

_axios.interceptors.request.use(
  async (config) => {
    (config.headers as AxiosHeaders).set(
      'Authorization',
      `Bearer ${localStorage?.getItem('token')}`
    );
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default _axios;
