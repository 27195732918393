import { AxiosResponse, AxiosRequestConfig } from 'axios';
import axios from './axiosConfig';

const config: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export interface Result {
  total: number;
  ok: number;
  error: number;
}

const uploadFile = async (formData: FormData): Promise<AxiosResponse<Result>> =>
  axios.post<FormData, AxiosResponse<Result>>('/upload', formData, config);
const uploadApi = { uploadFile };

export default uploadApi;
