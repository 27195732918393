import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import authApi from '../api/auth';

type Credentials = { username: string; password: string };

interface AuthContextType {
  token: any;
  user: any;
  error: string;
  isLoading: boolean;
  signIn: (credentials: Credentials, callback: VoidFunction) => void;
  signOut: (callback: VoidFunction) => void;
}

const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [token, setToken] = useState<any>(localStorage?.getItem('token'));
  const [user, setUser] = useState<any>(null);
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const responseProfile = await authApi.getProfile();
      const profile = responseProfile?.data;
      setUser(profile);
    } catch {
      localStorage?.removeItem('token');
      setToken(null);
      setError('Ha ocurrido un error al obtener el perfil de usuario.');
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (token) {
      fetchUser();
    }
  }, [fetchUser, token]);

  const signIn = async (credentials: Credentials, callback: VoidFunction) => {
    setError('');
    setIsLoading(true);
    try {
      const responseLogin = await authApi.performLogin(credentials);
      const token = responseLogin?.data?.access_token;
      localStorage?.setItem('token', token);
      setToken(token);
      await fetchUser();
      callback();
    } catch {
      localStorage?.removeItem('token');
      setToken(null);
      setError('Ha ocurrido un error al loguearse.');
    }
    setIsLoading(false);
  };

  const signOut = (callback: VoidFunction) => {
    localStorage?.removeItem('token');
    setToken(null);
    setUser(null);
    setError('');
    setIsLoading(false);
    callback();
  };

  const value = { user, token, error, isLoading, signIn, signOut };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const authToken = localStorage?.getItem('token');
  const location = useLocation();

  if (!authToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children;
};
