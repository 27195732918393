import * as React from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Typography,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth/Auth';

const MenuAppBar: React.FC = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleLogout = () => {
    handleClose();
    auth.signOut(() => navigate('/'));
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position='static' sx={{ backgroundColor: '#E6E7EE' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <img
          alt='left-logo'
          src='left-logo.png'
          height='100%'
          width={`${isMobile ? '1' : '2'}00px`}
        ></img>
        <Box display='flex' alignItems='center' justifyContent='center'>
          {auth?.token && auth?.user && (
            <Stack alignItems='center' justifyContent='center' direction='row'>
              <Typography color='#255AA3'>{auth.user.name}</Typography>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleMenu}
                sx={{ color: '#255AA3', marginX: '10px' }}
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} disabled>
                  Perfil
                </MenuItem>
                <MenuItem onClick={handleLogout}>Salir</MenuItem>
              </Menu>
            </Stack>
          )}
          <img
            alt='right-logo'
            src='right-logo.png'
            height={`${isMobile ? '3' : '6'}0px`}
            width={`${isMobile ? '3' : '6'}0px`}
          ></img>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default MenuAppBar;
