import { Routes, Route } from 'react-router-dom';
import MenuAppBar from './components/MenuAppBar';
import { AuthProvider, RequireAuth } from './Auth/Auth';
import { LoginPage } from './Login/LoginPage';
import { AdminPage } from './Admin/AdminPage';

const App: React.FC = () => (
  <AuthProvider>
    <MenuAppBar />
    <Routes>
      <Route
        path='/'
        element={
          <RequireAuth>
            <AdminPage />
          </RequireAuth>
        }
      />
      <Route path='/login' element={<LoginPage />} />
      <Route path='*' element={<LoginPage />} />
    </Routes>
  </AuthProvider>
);

export default App;
